<template>
  <AppHeader />
  <main class="main-container container-fluid d-flex pt-20 px-0 position-relative">
    <app-sidebar />
    <article class="main-content mt-10">
      <Suspense>
        <template #default>
          <app-searchs :search_id='search_id'/>
        </template>
        <template #fallback>
          <app-loading />
        </template>
      </Suspense>
      <AppFooter />
    </article>
  </main>
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
import AppSidebar from "@/components/layouts/AppSidebar.vue";
import AppLoading from "../../components/Spinners/AppLoading.vue";
import { Suspense } from "vue";
import AppSearchs from "../../components/Pages/AppSearchs.vue";
export default {
  name: "Searchs",
  props: {
    search_id: String
  },
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
    AppLoading,
    Suspense,
    AppSearchs,
  },
};
</script>

<style>
</style>